/** @jsx jsx **/
import { useState, Fragment, useEffect, useRef } from 'react'
import { jsx, Text } from 'theme-ui'

import { ProductItemComponentType } from '~/@types/models'

import CustomQuickShipFormDialog from '~/components/custom-quick-ship-form-dialog'
import { Button } from '~/components/button'

import { hubspotFormIds } from '~/common/hubspot'
import { HubspotForm } from '~/utils/hubspotForm'
import { useCustomerContext } from '~/context/customer-context'

interface Props {
  triggerProductName?: string
}

type EventListener = (e: Event) => void

export const openInquireForm = (args?: { product: { title: string } }) => {
  if (typeof window !== 'undefined') {
    document.dispatchEvent(new CustomEvent('openInquireForm', { detail: args }))
  }
}

export const closeInquireForm = () => {
  if (typeof window !== 'undefined') {
    document.dispatchEvent(new CustomEvent('closeInquireForm'))
  }
}

export const InquireForm = ({ triggerProductName }: Props) => {
  const [isFormDialogVisible, setFormDialogVisible] = useState<boolean>(false)
  const [product, setProduct] = useState<{ title: string } | null>(null)
  const id = '60ae7ea8-64eb-49ee-8b67-afda73b921fa'
  const productName = !triggerProductName ? '' : triggerProductName
  const { isTrade } = useCustomerContext()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('openInquireForm', ((e: Event) => {
        const product = (e as CustomEvent).detail?.product

        if (product) {
          setProduct(product)
        }

        setFormDialogVisible(true)
      }) as EventListener)

      document.addEventListener('closeInquireForm', ((e: Event) => {
        setProduct(null)
        setFormDialogVisible(false)
      }) as EventListener)
    }
  }, [])

  return (
    <Fragment>
      <CustomQuickShipFormDialog
        showDialog={isFormDialogVisible}
        close={() => setFormDialogVisible(false)}
        title="BESPOKE INQUIRY FORM"
        showPhone={false}
        subTitle={product?.title}
      >
        <HubspotForm
          formId={id}
          onReady={() => {
            const productNameInput = document.getElementsByName(
              'product_name'
            )[0] as HTMLInputElement
            const customerTypeInput = document.getElementsByName(
              'customer_type'
            )[0] as HTMLInputElement

            if (productNameInput) {
              productNameInput.value = product?.title || ''
            }

            if (customerTypeInput) {
              customerTypeInput.value = isTrade ? 'Trade' : 'Retail'
            }
          }}
        />
      </CustomQuickShipFormDialog>
    </Fragment>
  )
}
